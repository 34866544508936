$footer-height: 64px;

.outdated-browser {
	padding-top: 75px;
}
.outdated-browser-alert {
	padding: 20px 50px;
	background: #ff6276;
	color: #fff;
	font-size: 18px;
	font-family: Lato, sans-serif;
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	z-index: 9999;
}

// alert style
.donor-app-style {
	position: absolute;
	transform: translateX(-50%);
	margin: auto;
	left: 50%;
	bottom: $footer-height;
}

.roundup-row {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.roundup-col {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
// Onboarding Step CSS styles
.onboarding-step {
	.number {
		border: 1px solid $roundup-charcoal;
		border-radius: 1rem;
		width: 1.5em;
		height: 1.5em;
		text-align: center;
	}
}

.vcenter {
	align-items: center !important;
	display: flex !important;
}

.ellipsis {
	white-space: nowrap !important;
	overflow: hidden !important;
	text-overflow: ellipsis !important;
}

.most-popular {
	font-weight: 400;
	font-family: 'Yellowtail', cursive;
	color: $roundup-cerulean !important;
	font-size: 1.25rem !important;
}

body > #hubspot-messages-iframe-container.widget-align-right {
	bottom: 70px !important;
}
