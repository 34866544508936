@import "@roundupapp/component-library/src/styles/index.scss";




















































.nav-footer {
	box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
	max-width: 480px !important;
	width: 100%;
	margin: 0 auto;
	background: #fff;

	&-icon {
		margin-bottom: 5px;
		color: inherit !important;
	}
	.active {
		color: $roundup-navy !important;
	}
	.btn {
		cursor: pointer;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	p {
		color: inherit !important;
	}

	@media (max-width: 480px) {
		p {
			font-size: 14px !important;
			font-weight: $font-regular !important;
		}
	}
}
