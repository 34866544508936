@import "@roundupapp/component-library/src/styles/index.scss";






























































































































































.pending {
	opacity: 0.5 !important;
}
.money {
	font-weight: $font-medium !important;
}
.matched-banner {
	opacity: 1;
	position: absolute;
	z-index: 5;
	bottom: 8px;
	left: 20px;
	p {
		color: $roundup-navy;
		font-size: 12px !important;
		display: inline-block;
		margin-bottom: 0px !important;
	}
}
