/* Make type rendering look crisper */
/* Disable auto-enlargement of small text in Safari */
/* Enable kerning and optional ligatures */
html {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-size-adjust: 100%;
	text-rendering: optimizeLegibility;
	color: $roundup-charcoal !important;
	font-size: $font-size;
}

.font-heavy {
	font-family: $font-stack;
	font-weight: $font-heavy;
	&-serif {
		@extend .font-heavy;
		font-family: $font-stack-serif;
	}
}

.font-bold {
	font-family: $font-stack;
	font-weight: $font-bold;
	&-serif {
		@extend .font-bold;
		font-family: $font-stack-serif;
	}
}

.font-medium {
	font-family: $font-stack;
	font-weight: $font-medium;
	&-serif {
		font-family: $font-stack-serif;
		@extend .font-medium;
	}
}

.font-book {
	font-family: $font-stack;
	font-weight: $font-book;
	&-serif {
		@extend .font-book;
		font-family: $font-stack-serif;
	}
}

.font-light {
	font-family: $font-stack;
	font-weight: $font-light;
	&-serif {
		@extend .font-light;
		font-family: $font-stack-serif;
	}
}

.font-regular {
	font-family: $font-stack;
	font-weight: $font-regular;
	&-serif {
		@extend .font-regular;
		font-family: $font-stack-serif;
	}
}

h1 {
	@extend .font-medium;
	color: $roundup-charcoal;
	font-family: $font-stack-serif !important;
}

h2 {
	@extend .font-medium;
	color: $roundup-charcoal;
}

h3 {
	@extend .font-medium;
	color: $roundup-charcoal;
}

h4 {
	@extend .font-regular;
	color: $roundup-charcoal;
	font-size: 2.125rem !important;
}

h6 {
	font-family: $font-stack;
	color: $roundup-charcoal;
	font-weight: 600;
	font-size: 20px;
}

p,
span {
	font-family: inherit !important;
	color: $roundup-charcoal;
	font-size: 1rem;
}
