@import "@roundupapp/component-library/src/styles/index.scss";





































































































































































































































































































@import '@/styles/elements.scss';
.payment-card-form {
	height: 60px;
	h6 {
		font-size: 20px;
		color: $roundup-charcoal;
		font-weight: 600;
	}

	span {
		color: $roundup-charcoal;
	}

	.payment-options-edit-card-view-body {
		position: relative;
		display: flex;
		flex-direction: column;
		width: 100%;
		padding: 52px 24px 11px 24px;
		margin: 0;
		background-color: $roundup-bone;

		#lock-icon {
			color: $roundup-slate;
			position: absolute;
			top: -24px;
			transform: translateX(-50%);
			margin: auto;
			left: 50%;
			border: 1px solid $roundup-light-slate;
			border-radius: 30px;
			height: 50px;
			width: 50px;
			background-color: $roundup-light-grey;
		}
	}
	border: 1px solid $roundup-light-grey;
	.info-alert {
		background: '#2196f3';
	}
	.payment-type-button {
		border-radius: 4px !important;
		flex: 1 !important;
		height: 60px !important;
		color: $roundup-charcoal !important;
		width: 100%;
		&-active {
			background-color: #f0f4ff !important;
			border: 1px solid #3265e5 !important;
			color: $roundup-navy !important;
		}
		&-inactive {
			background-color: $roundup-light-grey !important;
			border: 1px solid $roundup-light-slate !important;
		}
	}

	.subtitle {
		color: rgba(0, 0, 0, 0.6);
		font-size: 14px;
	}
	#card {
		.input-row {
			background: $white;
			border-width: 0px;
			width: 100%;
			font-size: $font-size;
			margin-bottom: 10px;
			border-radius: 10px;
			outline: none;
			border: 1px solid $roundup-light-grey;
			padding: 0px 5px;
			label {
				padding: 22px 10px 20px;
				border-radius: 10px;
			}
		}
	}
}

.payment-options-edit-card-view::v-deep
	.roundup-button.roundup-button[disabled] {
	background-color: $roundup-light-grey !important;
	border: 1px solid $roundup-light-slate !important;
	color: $roundup-charcoal !important;
}
