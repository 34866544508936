// COLORS ARE IMPORTED FROM COMPONENT-LIBRARY
// THIS IS A COPY OF THE IMPORTED COLORS
// THIS FILE IS NOT IMPORTED ANYWHERE IN THIS REPO
// DO NOT USE THIS FILE OTHER THAN AS REFERENCE
// IF YOU NEED TO ADD A COLOR, ADD IT TO @roundupapp/component-library/src/styles/index

$roundup-primary: #2553cd;

// colors from Organization Brand Guide - page 18

// grayscale
$black: rgb(0, 0, 0);
$white: rgb(255, 255, 255);
$roundup-dark-gray: rgb(85, 87, 89);
$roundup-lighter-grey: #f6f7f9;
$roundup-light-grey: #dcdee3;
$roundup-lighter-grey-2: #f7f9fc;
$roundup-lightest-grey: #f6f9fa;
$roundup-medium-grey: #71788c;
$roundup-dark-grey: #434959;
$roundup-charcoal: #484a50;
$roundup-slate: #747a8d;
$roundup-light-slate: #b9bcc6;
$roundup-input-grey: rgba(113, 120, 140, 0.1);
$roundup-bone: #f5f6f8;

// greens
$roundup-lime-green: rgb(125, 200, 50);
$roundup-green: rgb(33, 134, 73);
$roundup-light-green: #88ee9a;
$roundup-darker-green: green;
$roundup-olive-green: rgb(106, 129, 59);
$roundup-mint: #1be6b9;
$roundup-mint-highlight: #1df3c4;
$roundup-alt-green: #3df4cb;
$roundup-darker-blue: #3b6ff7;

// blues
$roundup-light-blue: rgb(153, 218, 234);
$roundup-blue: rgb(0, 192, 222);
$roundup-navy-blue: rgb(0, 40, 86);
$roundup-cerulean: #1eadff;
$roundup-navy: #2553cd;
$roundup-light-navy: rgba(37, 83, 205, 0.2);
$roundup-malibu: #9bb8ff;
$roundup-navy-highlight: #3467ef;

// purples
$roundup-purple: #4337a7;
$roundup-purple-highlight: #5144bf;
$roundup-purple-shadow: #332a8c;

// reds
$roundup-red: #ff6376;
$roundup-red-highlight: #fa8b98;
$roundup-light-red: #ffe7ea;
$roundup-darker-red: #d31718;
$roundup-rose: #db2137;

// yellows
$roundup-banana: #feec37;

// gradients
$roundup-gradient1: #d3f9fb;
$roundup-gradient2: #d9f8ed;
$roundup-gradient3: #fff6dd;
$roundup-gradient4: #f0f4ff;
$roundup-gradient5: #f0f5ff;
$roundup-bluegrad1: #1cd4e3;
$roundup-bluegrad2: #3770fe;
