@import "@roundupapp/component-library/src/styles/index.scss";


































.roundup-step {
	margin-top: 16px !important;
	margin-bottom: 16px !important;
	.roundup-center {
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	.number {
		border: 1px solid $roundup-charcoal;
		border-radius: 1rem;
		width: 2em;
		height: 2em;
		line-height: 2em;
		text-align: center;
		color: white !important;
		background: $roundup-charcoal;
		font-size: 1rem !important;
	}
	.divider {
		color: $roundup-light-slate !important;
	}
	.step-title {
		font-size: 1rem !important;
		line-height: 1.5rem !important;
		color: $roundup-slate !important;
		margin-top: 16px !important;
		font-weight: 600 !important;
	}
	&.error-text {
		h3 {
			color: $roundup-red !important;
		}
		.number {
			color: $roundup-red !important;
			border: 1px solid $roundup-red;
			border-radius: 1rem;
			width: 1.5em;
			height: 1.5em;
			text-align: center;
		}
		hr {
			border-color: $roundup-red !important;
		}
	}
}
