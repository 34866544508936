@import "@roundupapp/component-library/src/styles/index.scss";





























































.roundup-alert::v-deep .v-snack__wrapper {
	width: 90% !important;
	min-width: 350px !important;
	max-width: 425px !important;
}
.roundup-alert::v-deep .v-snack__wrapper.success {
	background-color: #d9fdf5 !important;
	i {
		color: #07745c !important;
	}
	#text {
		color: #07745c !important;
		font-size: 0.875rem !important;
	}
}

.roundup-alert::v-deep .v-snack__wrapper.error {
	background-color: #ffe7ea !important;
	max-width: 90% !important;
	i {
		color: #db2137 !important;
	}
	#text {
		color: #db2137 !important;
		font-size: 0.875rem !important;
	}
}
