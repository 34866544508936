@import "@roundupapp/component-library/src/styles/index.scss";






















































































































































.confirm-card {
	background: $roundup-purple !important;
	color: white !important;
	padding-bottom: 12px !important;
	h1,
	h2,
	h3,
	h4,
	p {
		color: white !important;
		margin-bottom: 0px !important;
	}
	.confirm-icon {
		color: white !important;
		margin-right: 10px !important;
	}
	.details {
		max-width: 280px !important;
		.row {
			margin-top: 25px !important;
		}
	}
}
