@import "@roundupapp/component-library/src/styles/index.scss";
















































































































































































.thank-you-view {
	overflow: auto !important;
	background: white !important;
	.background-fade {
		background: linear-gradient(180deg, #1cd4e3 0%, #3770fe 100%);
	}
	.thank-header {
		width: 100vw !important;
		max-width: 480px !important;
		margin: auto !important;
		padding: 16px !important;
		padding-bottom: 50px !important;

		p {
			color: white !important;
		}
		.thank-heart {
			min-width: 300px !important;
			margin: auto !important;
			height: 150px !important;
			p {
				text-align: center !important;
				color: black !important;
				font-weight: $font-medium !important;
				font-size: 1.5rem !important;
				&.amount {
					margin-bottom: 0px !important;
				}
				&.small {
					font-weight: $font-regular !important;
					font-size: 0.875rem !important;
				}
			}
			::v-deep .v-responsive__sizer {
				@media screen and (max-width: 480px) {
					display: none !important;
				}
			}
		}
		.thank-text {
			font-weight: $font-medium !important;
			text-align: center !important;
			line-height: 1.5rem !important;
			margin-top: 16px !important;
		}
	}
	.thank-share-card {
		margin: 16px !important;
		background-color: white !important;
		margin-top: -50px !important;
		padding: 16px !important;
		margin-bottom: 62px !important;
		.share-header {
			line-height: 1.5rem !important;
			font-weight: $font-medium !important;
			text-align: center !important;
		}
		.share-body {
			margin-top: 32px !important;
			background-color: $roundup-bone !important;
			padding: 16px !important;
		}
	}
	::v-deep .v-snack__wrapper {
		width: 30% !important;
		max-width: 440px !important;
		min-width: 250px !important;
		background-color: #d9fdf5 !important;
		i {
			color: #07745c !important;
		}
		.v-snack__content {
			color: #07745c !important;
			font-size: 0.875rem !important;
		}
	}
}
