@import "@roundupapp/component-library/src/styles/index.scss";





























































































































































































































































.in-honor-of {
	.checkbox-label {
		width: 100% !important;
		color: $roundup-charcoal !important;
		margin-left: 10px;
		p.error-info {
			background-color: inherit;
			color: #db2137;
			font-size: 0.875rem;
			margin-bottom: 0px !important;
			margin-top: 0px !important;
		}
	}

	&-success {
		p {
			font-style: italic;
		}
	}
}

.checkbox::v-deep .v-input__slot {
	align-items: flex-start !important;
}
