@import "@roundupapp/component-library/src/styles/index.scss";






























































































































.detail-card-view {
	box-shadow: 0px 2px 10px 0px rgba(72, 74, 80, 0.5) !important;

	&-title {
		font-size: 18px !important;
		color: $roundup-charcoal !important;
		font-weight: 600 !important;
	}

	&-text {
		font-size: 16px !important;
		color: $roundup-charcoal !important;
	}
	&-stripe {
		background-color: $roundup-navy !important;
	}
	.no-bottom-border {
		border-bottom-left-radius: 0px !important;
		border-bottom-right-radius: 0px !important;
		border-bottom: 1px solid #dcdee3;
	}

	.header-background {
		background-color: $roundup-light-grey;
	}
}

.detail-card-view:hover.roundup-compact {
	cursor: pointer;
	box-shadow: 0px 0px 1px 1px $white, 0px 0px 6px 1px $roundup-bluegrad2 !important;
}
