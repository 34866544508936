@import "@roundupapp/component-library/src/styles/index.scss";










































































.header-bar {
	background: $roundup-navy !important;
	max-height: 60px !important;
	padding-left: 16px !important;
	padding-top: 4px !important;
}
.header-bar::v-deep .v-toolbar__content {
	padding-left: 0px !important;
}
