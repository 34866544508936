@import "@roundupapp/component-library/src/styles/index.scss";




































@import '@/styles/elements.scss';
.roundup-container {
	flex: 1;
	background: $white;
	padding: 72px 16px $footer-height 16px !important;
	overflow-y: auto;
	box-shadow: none !important;
	&.prominent {
		padding-top: 130px !important;
	}
	&.no-header {
		padding-top: 0px !important;
	}
}
