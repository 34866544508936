.v-messages__message {
	font-size: 12px !important;
}

.v-list-item__title {
	color: $roundup-charcoal !important;
	font-weight: $font-medium !important;
}
.v-list-item__subtitle {
	color: $roundup-slate !important;
}

.v-app-bar-title__content {
	width: unset !important;
}

.filter-modal {
	hr {
		margin: 20px 0px 20px 0px;
	}

	h3 {
		margin: 8px 0px 16px 0px;
	}

	.roundup-input {
		margin: 20px 0px;
	}

	.v-sheet {
		padding: 20px 16px 0px 16px;
	}

	hr.mx-4 {
		margin: 4px 16px 0px 16px;
	}

	.v-menu__content {
		max-height: 200px !important;
	}

	fieldset {
		padding-left: 8px !important;
	}

	.v-text-field__slot {
		.v-label--active {
			left: -3px !important;
			top: 14px !important;
		}
	}

	.v-select__slot {
		.v-label--active {
			left: -4px !important;
			top: 14px !important;
		}
		.v-label--active:nth-child(1) {
			left: -7px !important;
			top: 14px !important;
		}
	}
}

.v-dialog {
	overflow-y: visible !important;
}
.v-row {
	margin: 0 !important;
}
.v-divider {
	color: #979797 !important;
}

.v-progress-circular--indeterminate:not(.v-progress-circular--visible) > svg,
.v-progress-circular--indeterminate:not(.v-progress-circular--visible)
	.v-progress-circular__overlay {
	animation-play-state: inherit !important;
}

.v-list-item__subtitle {
	&.wrap-text {
		-webkit-line-clamp: unset !important;
		white-space: inherit !important;
		line-height: 1.2rem !important;
	}
}

.v-list-item__action {
	i {
		color: $roundup-slate !important;
	}
}
