@import "@roundupapp/component-library/src/styles/index.scss";



























.card-error {
	background-color: #ffe7ea !important;
	p,
	i {
		color: #db2137 !important;
	}
	p {
		margin: 0px 8px !important;
	}
	&:hover {
		cursor: pointer;
	}
}
